<!-- CLOSE -->
<ui-kit-button-icon
  *ngIf="isCloseable"
  class="callout-close"
  [icon]="'x'"
  (onClick)="onClose()">
</ui-kit-button-icon>

<!-- BADGE -->
<div class="callout-badge">
  <ui-kit-icon [icon]="icon" [size]="24"></ui-kit-icon>
</div>

<section class="callout-content">

  <!-- TITLE -->
  <h1 *ngIf="title">{{ title | translate }}</h1>

  <!-- DESCRIPTION -->
  <p *ngFor="let description of descriptions">{{ description | translate }}</p>

  <!-- CUSTOM CONTENT -->
  <ng-content></ng-content>

</section>
