import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ERROR_REDIRECT_ROUTE } from '@config/translate/translate.config';
import { APP_CONFIG, environment, IAppConfig } from '@env';
import { AAGUID, GeneralConfig, LanguageCode, PartnerType, ScriptLoaderService, TranslateService } from '@interticket/core';
import { ParamStoreService } from '@interticket/customer-facing-ui-kit';
import { EMPTY, firstValueFrom, of } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { AuthUserStoreService } from '../store/auth-user-store.service';

export function appInitializerFactory(appInitializer: AppInitializerService): () => Promise<boolean> {
  return (): Promise<boolean> => appInitializer.load();
}

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {

  private jwtPartnerId?: AAGUID | null = null;
  private jwtRegionId?: AAGUID | null = null;

  constructor(
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private authService: AuthService,
    private authUserStore: AuthUserStoreService,
    private injector: Injector,
    private router: Router,
    private paramStore: ParamStoreService,
  ) { }

  load(): Promise<any> {
    const isLoginPage: boolean = window.location.hash.startsWith('#/auth/login');

    this.jwtPartnerId = this.authService.getPartnerIdFromJwtAuthUrl(window.location.href);
    this.jwtRegionId = this.authService.getPartnerIdFromJwtAuthUrl(window.location.href, true);

    return firstValueFrom(
      (isLoginPage ? of(false) : this.authService.load())
        .pipe(
          tap(() => this.subscribeToPartnerConfig()),
          switchMap(() => this.initTranslateService()),
          catchError((error) => {
            console.error(error);
            this.router.navigate(['error', 'server-error']);
            return EMPTY;
          }),
        )
    );
  }

  private initTranslateService(): Promise<void> {
    const generalConfig: GeneralConfig = this.authUserStore.partnerConfig?.general;

    return this.injector.get(TranslateService).init({
      partnerId: this.getPartnerIdForTranslate(),
      regionPartnerId: this.authUserStore.partnerConfig?.regionPartnerId || this.jwtRegionId,
      errorRedirectRoute: ERROR_REDIRECT_ROUTE,
      translationTags: this.appConfig.translationTags,
      language: {
        supported: generalConfig?.supportedLanguages,
        default: generalConfig?.defaultLanguageCode.toLowerCase() as LanguageCode,
      },
    });
  }

  private getPartnerIdForTranslate(): string {
    if (environment.isMock) {
      return null;
    }

    return this.authUserStore.partnerConfig?.partnerId || this.jwtPartnerId || PartnerType.PLATFORM_PROVIDER;
  }

  private subscribeToPartnerConfig(): void {
    this.authUserStore.partnerConfig$
      .pipe(
        filter(partnerConfig => !!partnerConfig),
        take(1),
        tap(partnerSettings => this.paramStore.patchState({ defaultTimezone: partnerSettings.general.defaultTimezone })),
      )
      .subscribe({
        next: partnerConfig => this.loadScriptsToHeader(partnerConfig.general.defaultLanguageCode.toLowerCase() as LanguageCode),
      });
  }

  private loadScriptsToHeader(defaultLanguageCode: LanguageCode): Promise<any> {
    if (defaultLanguageCode === LanguageCode.EN || defaultLanguageCode === LanguageCode.HU) {
      return this.injector.get(ScriptLoaderService).load({ ...!!this.appConfig.crisp && { crisp: this.appConfig.crisp } });
    }
  }

}
