import { Icon } from '@interticket/customer-facing-ui-kit';

export const usedIcons: Icon[] = [
  Icon.ADD,
  Icon.ARROW_DOWN,
  Icon.ARROW_LEFT_ROUNDED,
  Icon.ARROW_LEFT,
  Icon.ARROW_RIGHT_ROUNDED,
  Icon.ARROW_RIGHT,
  Icon.ARROW_UP,
  Icon.BASKET,
  Icon.CALENDAR,
  Icon.CHECK,
  Icon.CLOCK,
  Icon.CLOSE_BUTTON,
  Icon.CLOSE_THICK,
  Icon.CLOSE,
  Icon.COPY,
  Icon.DELETE_CROSS,
  Icon.DELETE,
  Icon.EARTH,
  Icon.EDIT3,
  Icon.FACEBOOK,
  Icon.GIFT,
  Icon.GLOBE,
  Icon.IMAGE_NOT_FOUND,
  Icon.IMAGE,
  Icon.LIVE_STREAM_VIDEO,
  Icon.LIVE_STREAM,
  Icon.LIVESTREAM_VIDEO,
  Icon.LIVESTREAM,
  Icon.LOGOUT,
  Icon.MANUAL_ICON,
  Icon.MAP_PIN,
  Icon.MENU_DOTS,
  Icon.MINUS,
  Icon.NORMAL,
  Icon.PENCIL,
  Icon.PLUS_RECTANGLE,
  Icon.PLUS,
  Icon.PROFILE,
  Icon.SEARCH,
  Icon.SEND_MESSAGE,
  Icon.SHARE,
  Icon.SHOPPING_CART,
  Icon.SWITCH_DEVICE_ICON,
  Icon.TEHATER_MODE,
  Icon.TICKET,
  Icon.USER,
  Icon.USER_PLUS,
  Icon.VENUE,
  Icon.VIDEO,
  Icon.VIEWERS,
  Icon.WARNING,
  Icon.LINE,
];
