<div class="modal-header ui-kit-palette-{{ palette }}">
  <span class="modal-title">

    <!-- ICON -->
    <ui-kit-icon *ngIf="icon" [icon]="icon" />

    <!-- TITLE -->
    <div class="main-title">
      {{ title | translate }}

      <!-- SUBTITLE -->
      <div *ngIf="subtitle || subtitleTemplate" class="modal-subtitle">
        {{ subtitle | translate }}

        <!-- SUBTITLE TEMPLATE PROJECTION -->
        <ng-container *ngTemplateOutlet="subtitleTemplate" />
      </div>

    </div>

  </span>

  <!-- CLOSE X -->
  <ui-kit-button-icon
    *ngIf="closeable"
    [icon]="'x'"
    [isDisabled]="isDisabled"
    (onClick)="onXClicked()" />

</div>

<!-- CONTENT -->
<mat-dialog-content [class.full-height-content]="fullHeightContent" [class.internal-scroll-container]="internalScrollContainer">
  <div
    *ngIf="!isLoading else loader"
    class="modal-content"
    [class.reset-padding]="!useGridAroundContent">
    <div class="row">
      <div
        class="columns small-12"
        [class.reset-padding]="!useGridAroundContent">
        <ng-content />
      </div>
    </div>
  </div>
</mat-dialog-content>

<!-- ACTIONS -->
<mat-dialog-actions *ngIf="actionsTemplate">
  <div class="row">
    <div class="columns small-12">
      <div class="ui-kit-button-container flex-end">
        <ng-template [ngTemplateOutlet]="actionsTemplate" />
      </div>
    </div>
  </div>
</mat-dialog-actions>

<!-- LOADER -->
<ng-template #loader>
  <ui-kit-spinner
    [fillContainer]="true"
    [palette]="palette" />
</ng-template>
